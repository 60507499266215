import './PublisherInfosComponent.css'
import LabelCardComponent from '../LabelCardComponent/LabelCardComponent';
import { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import { toast } from 'react-toastify';
import 'react-day-picker/dist/style.css';
import {ROOT_DOMAIN_API} from "../../Constant";

function PublisherInfosComponent(props) {
  const [adminList, setAdminList] = useState([]);
  const [prospectorList, setProspectorList] = useState([]);
  const [publisherInfos, setPublisherInfos] = useState({});
  const [selectedResult, setselectedResult] = useState();
  const [selectedResultActivation, setselectedResultActivation] = useState();
  const [selectedResultProspector, setselectedResultProspector] = useState();
  const [selectedDay, setSelectedDay] = useState();
  const [dayPickerVisibility, setDayPickerVisibility] = useState(false);
  const [lastContactDate, setLastContactDate] = useState("****-**-**");
  const [userFraud, setUserFraud] = useState(-1);
  const [reminderText, setReminderText] = useState("");
  const [perfSinceLastBill, setPerfSinceLastBill] = useState("***");

  const toggleDayPickerVisibility = function(){
    if(dayPickerVisibility){
      setDayPickerVisibility(false);
    } else {
      setDayPickerVisibility(true);
    }
  }

  const handleMoneyboxAccess = async (siteId) => {
    try {
      const response = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/crm_user/bo_moneyboxToken`, {
        params: { site_id: siteId },
      });

      if (response.data.status && response.data.results) {
        let url = "https://www.themoneytizer.com/new_manager/login&site_id=" + siteId + "&tbm_hash=" + response.data.results.tbm_hash;
        window.open(url.toString(), '_blank');
      } else {
        toast.error('Failed to connect to Moneybox', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    } catch (error) {
      toast.error('Error while trying to connect to moneybox', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
      });
    }
  };

  const getSiteIdForUserId = async () => {
    if(!props.userId){
      return;
    }

    try {
      const response = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/crm_user/bo_getSiteIdForUserId`, {
          params: { user_id: props.userId },
      });

      if (response.data.status && response.data.results) {
          const siteId = response.data.results;
          handleMoneyboxAccess(siteId);
      }
    } catch(error) {
      console.log(error);
    }
  };

  const handleMoneyboxUserIdSubmit = () => {
      getSiteIdForUserId();
  
  };


  const handleSelection = value => {
    (async () => {
        try {
          await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_updateUserAdmin", {user_id: props.userId, admin_id : value.adm_id});
          setselectedResult(value);
        } catch (error) {
            console.error(error);
        }
      })();
  }

  const handleSelectionActivation = value => {
    (async () => {
        try {
          await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_updateUserAdminActivation", {user_id: props.userId, admin_id : value.adm_id});
          setselectedResultActivation(value);
        } catch (error) {
            console.error(error);
        }
      })();
  }

    const handleSelectionProspector = value => {
        console.log(value);
        (async () => {
            try {
                await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_updateUserProspection", {user_id: props.userId, user_pros_id : value.user_pros_id});
                setselectedResultProspector(value);
            } catch (error) {
                console.error(error);
            }
        })();
    }

  const loadOptions = (inputValue) => {
    if(adminList.length>0){
      return;
    }
    return axios(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getAdminList")
    .then(res => res.data)
    .then(data => {

     const optionsAdminList = [{
        "adm_name": "Select...",
        "adm_id": "0",
        "adm_email": ""
      }, ...data.results];

      setAdminList(optionsAdminList);
      
      return optionsAdminList
    })
  };

    const loadOptionsProspector = (inputValue) => {
        if(prospectorList.length>0){
            return;
        }
        return axios(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getSalesList")
            .then(res => res.data)
            .then(data => {

                const optionsProspectorList = [{
                    "id": "Select...",
                    "adm_id": "0",
                    "adm_name": ""
                }, ...data.results];

                setProspectorList(optionsProspectorList);

                return optionsProspectorList
            })
    };

  const addReminder = function(){
    if(!selectedDay){
      return;
    }
    const payload = {description: reminderText, userId: props.userId, date: format(selectedDay, 'yyyy-MM-dd') };
    setDayPickerVisibility(false);
    (async () => {
      try {
        await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_addReminder", payload);
        toast.success('Reminder has been added.', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          }); 
        setReminderText("");
      } catch (error) {
          console.error(error);
      }
    })();
  }

  useEffect(() => {

    (async () => {
      if(!props.userId){
        return;
      }
      try {
        setselectedResult(null);
        setselectedResultActivation(null);
        setselectedResultProspector(null);
        const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getUserFromID", {userId: props.userId})
        const obj = await resp.data;
        setPublisherInfos(obj.results.user);
        setUserFraud(obj.results.user.user_fraud);
      } catch (error) {
          console.error(error);
      }
    })();

    (async () => {
      try {
        if(!props.userId){
          return;
        }
        setselectedResult(null);
        setselectedResultActivation(null);
        setselectedResultProspector(null);
        const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_lastContact", {user_id: props.userId})
        const obj = await resp.data;
        setLastContactDate(obj.results.date);
      } catch (error) {
          console.error(error);
      }
    })();
  }, [props.userId]);


  const updateUserFraud = function(){
    (async () => {
      try {
        if(!props.userId){
          return;
        }
        const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_updateUserFraud", {user_id: props.userId, fraud: userFraud})
        await resp.data;
      } catch (error) {
          console.error(error);
      }
    })();
  }

  useEffect(() => {
    (async () => {
      try {
        if(!props.userId){
          console.log(props.userId);
          return;
        }
        const resp = await axios.get(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_perfSinceLastBill", {params: {user_id: props.userId}})
        await resp.data;
        setPerfSinceLastBill(resp.data.results)
      } catch (error) {
          console.error(error);
      }
    })();
  }, [props.userId]);

  return (
    <div className="PublisherInfos">
        <div className="FlexRow FlexSpaceAround AlignCenter">
            <div className="FlexCol">
                <h3 className="MT0 MB0">{publisherInfos.user_id} | {publisherInfos.user_firstname} {publisherInfos.user_lastname}</h3>
                <p className="LastContactContainer MT0 MB0">Date of last contact: {lastContactDate}</p>
            </div>
            <div className="FlexRow AlignCenter">
                <LabelCardComponent icon="fa-solid fa-arrow-up-short-wide" color="BBlue"
                                    text={"Priority " + (publisherInfos.prio ? publisherInfos.prio : 0)}></LabelCardComponent>
                <LabelCardComponent icon="fa-solid fa-bolt" color="BRed"
                                    text={publisherInfos.actif ? "Active" : "Inactive"}></LabelCardComponent>
                <LabelCardComponent icon="fa-solid fa-earth-europe" color="BOrange"
                                    text={(publisherInfos.user_plateform ? publisherInfos.user_plateform : 'none')}></LabelCardComponent>
                <LabelCardComponent icon="fa-regular fa-credit-card" color={"BRed"}
                                    text={"Net " + (publisherInfos.user_net30 === 1 ? "30" : "60")}/>
                <button onClick={() => handleMoneyboxUserIdSubmit()}
                        className="ActionButton BGreen ML5 Block FitContent IconLinkMoneybox"><i
                    className="fa-solid fa-piggy-bank"></i></button>
                <button className="ActionButton BRed Block FitContent IconLinkMoneybox ML10"
                        onClick={updateUserFraud}>Update fraud status
                </button>
                <input className="ActionInput ML5 MR20 Width5" type="number"
                       onChange={(e) => setUserFraud(e.target.value)} value={userFraud}/>
                {publisherInfos.user_pipedrive_label ?
                    <div className={"LabelCard BGreen"}><span>Pipedrive {publisherInfos.user_pipedrive_label}</span>
                    </div> : ''}
                {publisherInfos.user_suspended ? <div className={"LabelCard BBlack"}><span>Suspended {publisherInfos.user_suspended_date ? "(" + publisherInfos.user_suspended_date + ")" : ""}</span></div> : ''}
                {publisherInfos.user_moderation === 0 ? <div className={"LabelCard BBlack"}><span>Deleted</span></div> : ''}
                {publisherInfos.preavis ? <div className={"LabelCard BRed"}><span>Notice : {publisherInfos.preavis}</span></div> : ''}
            </div>
            <div className="FlexCol MR20">
                <p className="MT0 MB0">Prospector</p>
                <AsyncSelect
                    isSearchable={false}
                    className="SelectField"
                    cacheOptions
                    defaultOptions
                    value={
                        selectedResultProspector ? selectedResultProspector : (prospectorList.filter(e => e.adm_id === publisherInfos.user_pros_id))
                    }
                    getOptionLabel={e => e.adm_name}
                    getOptionValue={e => e.adm_id}
                    loadOptions={loadOptionsProspector}
                    onChange={handleSelectionProspector}
                />
            </div>
            <div className="FlexCol">
                <p className="MT0 MB0">Assignation Support</p>
                <AsyncSelect
                    isSearchable={false}
                    className="SelectField"
                    cacheOptions
                    defaultOptions
                    value={
                        selectedResult ? selectedResult : (adminList.filter(adm => adm.adm_id === publisherInfos.user_admin_id))
                    }
                    getOptionLabel={e => e.adm_name}
                    getOptionValue={e => e.adm_id}
                    loadOptions={loadOptions}
                    onChange={handleSelection}
                />
                <p className="MT10 MB0">Assignation Activation</p>
                <AsyncSelect
                    isSearchable={false}
                    className="SelectField"
                    cacheOptions
                    defaultOptions
                    value={
                        selectedResultActivation ? selectedResultActivation : (adminList.filter(adm => adm.adm_id === publisherInfos.user_admin_id_activation))
                    }
                    getOptionLabel={e => e.adm_name}
                    getOptionValue={e => e.adm_id}
                    loadOptions={loadOptions}
                    onChange={handleSelectionActivation}
                />
            </div>
            <div>
                <i onClick={toggleDayPickerVisibility} className="fa-solid fa-bell TYellow Reminder"></i>
                <div className="ContainerDayPicker">
                    <div className={'FlexCol ReminderObject ' + (dayPickerVisibility ? '' : 'Hide')}>
                        <div className='FlexRow'>
                            <textarea onChange={(e) => {
                                setReminderText(e.target.value)
                            }}></textarea>
                            <DayPicker
                                format="M/D/YYYY"
                                className={"DayPickerObject"}
                                mode="single"
                                selected={selectedDay}
                                onSelect={setSelectedDay}
                            />
                        </div>
                        <button className="ActionButton BRed MT5" onClick={() => addReminder()}>Add reminder</button>
                    </div>

                </div>

            </div>
        </div>
        <div className='FlexRow'>  
          <p className='PerfSinceLastBill MT0 MB0'>Revenues since last bill: {perfSinceLastBill} €</p>
        </div>
    </div>
  );
}

export default PublisherInfosComponent;
  