import './ContactComponent.css'
import { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import {ROOT_DOMAIN_API} from "../../Constant";

function ContactComponent(props) {
    const [otherMailValue, setOtherMailValue] = useState("");
    const [otherMailValue2, setOtherMailValue2] = useState("");
    const [otherTelValue, setOtherTelValue] = useState("");

    const saveOtherMail = function(){
        if(otherMailValue===""){
            return;
        }
        (async () => {
            try {
            await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_updateOtherMail", {user_id: props.userId, other_mail: otherMailValue})
            toast.success('Email 2 has been updated.', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            } catch (error) {
                console.error(error);
            }
        })();
    }

    const saveOtherMail2 = function(){
        if(otherMailValue2===""){
            return;
        }
        (async () => {
            try {
                await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_updateOtherMail2", {user_id: props.userId, other_mail: otherMailValue2})
                toast.success('Email 3 has been updated.', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } catch (error) {
                console.error(error);
            }
        })();
    }

    const handleOtherMail = event => {
        setOtherMailValue(event.target.value);
    };

    const handleOtherMail2 = event => {
        setOtherMailValue2(event.target.value);
    };

    const saveOtherTel = function(){
        if(otherTelValue===""){
            return;
        }
        (async () => {
            try {
            await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_updateOtherPhone", {user_id: props.userId, other_phone: otherTelValue})
            toast.success('Tel 2 has been updated.', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            } catch (error) {
                console.error(error);
            }
        })();
    }

    const handleOtherTel = event => {
        setOtherTelValue(event.target.value);
    };

    return (
        <div className={"Contact " + props.childClass}>
            <h3>Contact info</h3>
            <div className="FlexRow FlexSpaceAround">
                <p className="M0 Width30"><i className="fa-solid fa-location-dot TGrey MR10"></i>Address</p>
                <p className="M0 Width70 SmallFont">{props.publisherInfos.cac_name}</p>
            </div>
            <div className="FlexRow FlexSpaceAround">
                <p className="MT0 MB0 Width30"><i className="fa-solid fa-phone TGrey MR5 MR10"></i>Tel</p>
                <p className="MT0 MB0 Width70 SmallFont">{props.publisherInfos.user_area_code_phone ? ("+" + props.publisherInfos.user_area_code_phone + " - ") : ""}{props.publisherInfos.user_phone}</p>
            </div>
            <div className="FlexRow FlexSpaceAround">
                <p className="MT0 MB0 Width30"><i className="fa-solid fa-at TGrey MR5 MR10"></i>Tel 2</p>
                <div className="Width70">
                    <input className="MailField" type="text" name="tel2" onChange={handleOtherTel}
                           defaultValue={props.publisherInfos.user_other_phone}/>
                    <button className="ActionButton" onClick={saveOtherTel}><i className="fa-solid fa-check BRed"></i>
                    </button>
                </div>
            </div>
            <div className="FlexRow FlexSpaceAround">
                <p className="MT0 MB0 Width30"><i className="fa-solid fa-at TGrey MR5 MR10"></i>Email</p>
                <p className="MT0 MB0 Width70 SmallFont">{props.publisherInfos.user_mail}</p>
            </div>
            <div className="FlexRow FlexSpaceAround">
                <p className="MT0 MB0 Width30"><i className="fa-solid fa-at TGrey MR5 MR10"></i>Email 2</p>
                <div className="Width70">
                    <input className="MailField" type="mail" name="mail2" onChange={handleOtherMail}
                           defaultValue={props.publisherInfos.user_other_mail}/>
                    <button className="ActionButton" onClick={saveOtherMail}><i className="fa-solid fa-check BRed"></i>
                    </button>
                </div>
            </div>
            <div className="FlexRow FlexSpaceAround">
                <p className="MT0 MB0 Width30"><i className="fa-solid fa-at TGrey MR5 MR10"></i>Email 3</p>
                <div className="Width70">
                    <input className="MailField" type="mail" name="mail3" onChange={handleOtherMail2}
                           defaultValue={props.publisherInfos.user_other_mail2}/>
                    <button className="ActionButton" onClick={saveOtherMail2}><i className="fa-solid fa-check BRed"></i>
                    </button>
                </div>
            </div>
            <div className="FlexRow FlexSpaceAround">
                <p className="MT0 MB0 Width30"><i className="fa-brands fa-google TGrey MR5 MR10"></i>Email GAM</p>
                <p className="MT0 MB0 Width70 SmallFont">{props.publisherInfos.email_GAM + " | " + props.publisherInfos.id_GAM}</p>
            </div>
        </div>
    );
}

export default ContactComponent;
  